import { asyncThunkCreator, buildCreateSlice } from "@reduxjs/toolkit";

export const sliceWithThunks = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

export const decodeFileName = (fileName: string): string => {
  try {
    return decodeURIComponent(fileName);
  } catch (error) {
    console.error('Error decoding file name:', error);
    return fileName; 
  }
};
