// import SidebarItem from "./sidebar-item";
// import SidebarItemGroup from "./sidebar-item-group";
import React, { useEffect, useState, useCallback } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { logout, selectUser } from "../../../redux/authSlice";
import { deleteAccount, deleteVideos, selectDeleteAccountStatus } from "../../../redux/storageSlice";
import { Avatar, List, ListItemButton, Skeleton, ListItemText, ListItemIcon, IconButton } from "@mui/material";
import { User } from "../../../lib/authentication/user";
import Logo from "../../../lib/ui/logo";
import { Close, Dashboard, DeleteForever, Logout, Menu, UploadFile, Report } from "@mui/icons-material";
import { pink } from '@mui/material/colors';
import { selectResults } from "../../../redux/sinceritySlice";
import Swal from "sweetalert2";
import avatar from "../../../assets/avatar.png";

enum OptionCommand {
  logout = 1,
  delete = 2,
}

export interface OptionLink {
  link: string;
  title: string;
  children: OptionLink[];
  extra: React.ReactNode[];
  command?: OptionCommand;
  disabled?: boolean;
}

export default function Sidebar() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const items = useAppSelector(selectResults);
  const deleteAccountStatus = useAppSelector(selectDeleteAccountStatus);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);


  // handle delete all videos button being clicked
  function handleDeleteAll() {
    Swal.fire({
      title: "Delete all your uploads?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          // create list of objects containing srcId and fileExtension
          let deleteVideosList: any[] = [];
          for (const item of items) {
            deleteVideosList.push({
              srcId: item.srcID,
              fileExtension: item.fileExtension
            })
          }
          dispatch(deleteVideos(deleteVideosList));
          Swal.fire({
            title: "Deleted!",
            text: "All uploads deleted.",
            icon: "success"
          }).then(() => {
            if (window.location.pathname === '/dashboard') {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          });
        } catch (e) {
          Swal.fire({
            title: "Error!",
            text: "There was an error deleting your upload.",
            icon: "error"
          });
        }
      }
    });
  }

  // function constructNode(option: OptionLink) {
  //   if (option.children.length) {
  //     return (
  //       <SidebarItemGroup
  //         key={option.link}
  //         title={option.title}
  //         link={option.link}
  //         extra={option.extra}
  //         handleClick={undefined}
  //       >
  //         {option.children.map((child) => constructNode(child))}
  //       </SidebarItemGroup>
  //     );
  //   } else {
  //     return (
  //       <SidebarItem
  //         key={option.link}
  //         link={option.link}
  //         handleClick={
  //           option.command === OptionCommand.logout ? handleLogout : undefined
  //         }
  //       >
  //         <div className="flex items-center">
  //           <div className="h-4 w-4 ml-1 mr-1 text-primary dark:text-primary-dark">
  //             {option.extra}
  //           </div>
  //           <div>{option.title}</div>
  //         </div>
  //       </SidebarItem>
  //     );
  //   }
  // }

  const noProfile = () => {
    return <Skeleton variant="rectangular" height={72} />;
  };

  const profile = (user: User) => {
    return (
      <div className="sticky inset-x-0 bottom-0 text-xs font-medium">
        <div className="border-t">
          <Link to="#" className="flex items-center gap-2 p-4 selection" onClick={handleDeleteAccount}>
            <DeleteForever className="h-10 w-10"></DeleteForever>
            <div>
              <p>
                <strong className="block">Delete Account</strong>
              </p>
            </div>
          </Link>
        </div>
        <div className="border-t">
          <Link to="#" className="flex items-center gap-2 p-4 selection">
            <Avatar
              alt=""
              src={avatar}
              className="h-10 w-10"
            />
            <div>
              <p>
                <strong className="block">{user.lastName? user.firstName + " " + user.lastName : user.firstName}</strong>
                <span>{user.email}</span>
              </p>
            </div>
          </Link>
        </div>
      </div>
    );
  };

  // delete account and ask for confirmation
  const handleDeleteAccount = () => {
    Swal.fire({
      title: "Delete your account?",
      text: "Your account and all your data will be deleted." +
        " You will no longer be able to login and use the service!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAccount());
      }
    });
  }

  // if delete account succeeds, log them out else throw an error
  useEffect(() => {
    if (deleteAccountStatus === "failed") {
      Swal.fire({
        title: "Error!",
        text: "There was an error deleting your account.",
        icon: "error"
      });
    } else if (deleteAccountStatus === "success") {
      handleLogout();
      window.location.href = "https://voicera.io/voicera-labs/";
    }
  }, [deleteAccountStatus, handleLogout]);

  const [isOpen, setIsOpen] = useState(false)
  const [isMdOrLarger, setIsMdOrLarger] = useState(window.innerWidth >= 768);

  const location = useLocation();

  const handleResize = () => {
    setIsMdOrLarger(window.innerWidth >= 768);
  };

  const handleSidebarClick = (value: OptionLink) => {
    setIsOpen(false)
    if (value.command === OptionCommand.logout) {
      handleLogout()
    } else if (value.command === OptionCommand.delete) {
      handleDeleteAll();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const links: OptionLink[] = [
    {
      title: "Dashboard",
      link: "/dashboard",
      children: [],
      extra: [
        <Dashboard key="/dashboard" />,
      ],
    },
    {
      title: "Upload",
      link: "/dashboard/upload",
      children: [],
      extra: [
        <UploadFile key="/dashboard/uploads" />
      ],
    }, ...(user && user.email.endsWith("@voicera.io")
      ? [{
        title: "Reporting",
        link: "/dashboard/reporting",
        children: [],
        extra: [
          <Report key="/dashboard/reporting" />
        ],
      }]
      : []),
    {
      title: "Delete All",
      link: "#",
      children: [],
      disabled: items.length === 0,
      extra: [
        <DeleteForever key="/delete" />
      ],
      command: OptionCommand.delete,
    },
    {
      title: "Logout",
      link: "#",
      children: [],
      extra: [
        <Logout key="/logout" />,
      ],
      command: OptionCommand.logout,
    },
  ];


  return (

    <div className="flex">
      <div className="md:hidden">
        {!isOpen && <button
          onClick={() => setIsOpen(true)}
        >
          <Menu sx={{ color: pink[500] }} className="m-4" />
        </button>}

      </div>


      <div className={`${isMdOrLarger ? 'flex' : isOpen ? 'flex' : 'hidden'}`}>
        <div className="flex flex-col justify-between p-4 border-e">
          <div className="flex flex-col justify-center">
            <div className="flex flex-row justify-between">
              <div>
                <Logo />
              </div>
              <div className="md:hidden">
                <IconButton onClick={() => setIsOpen(false)}>
                  <Close />
                </IconButton>
              </div>
            </div>
            <List disablePadding>{links.map((value) => (
              <ListItemButton selected={location.pathname === value.link} component={NavLink} to={value.link} onClick={() => handleSidebarClick(value)} disabled={value.disabled}>
                <ListItemIcon>
                  {value.extra[0]}
                </ListItemIcon>
                <ListItemText primary={value.title}></ListItemText>
              </ListItemButton>
            ))}</List>
          </div>
          {user ? profile(user) : noProfile()}
        </div>
      </div>
    </div>

  );
}
