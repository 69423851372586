import { Button } from "@mui/material";
import React from "react";

export default function FormButton({
  handleClick,
  isDisabled,
  children,
  isLoading = false,
  group = undefined,
  loadingTitle = "Loading...",
}: {
  handleClick: () => void;
  isDisabled: boolean;
  children: React.ReactNode;
  isLoading?: boolean;
  group?: string;
  loadingTitle?: string;
}) {
  function showLoading() {
    if (!isLoading) return;

    return (
      <div className="flex items-center space-x-2">
        <div className="h-[24px] w-[24px] border-white loading animate-spin rounded-full border-2 border-t-primary-accent" />
        <div className="grow">{loadingTitle}</div>
      </div>
    );
  }

  function showBody() {
    return children;
  }

  return (
    <Button
      variant="contained"
      sx={{
        textTransform: "none",
        height: '43px',
        background: isDisabled
          ? "linear-gradient(90deg, #B3B3B3 0%, #E0E0E0 100%)"
          : "linear-gradient(90deg, #392B7A 0%, rgba(57, 43, 122, 0.67) 100%)",
        // background: "linear-gradient(90deg, #392B7A 0%, rgba(57, 43, 122, 0.67) 100%)",
        borderRadius: "10px",
        fontSize: "18px"
      }}
      className="flex w-full justify-center"
      onClick={handleClick}
      disabled={isDisabled}
    >
      {showLoading() || showBody()}
    </Button>
  );
}