/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportFeedbackSummary
 */
export interface ReportFeedbackSummary {
    /**
     * 
     * @type {string}
     * @memberof ReportFeedbackSummary
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportFeedbackSummary
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportFeedbackSummary
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportFeedbackSummary
     */
    feedbackRating?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportFeedbackSummary
     */
    feedbackComment?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportFeedbackSummary
     */
    feedbackTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportFeedbackSummary
     */
    fileName?: string;
}

/**
 * Check if a given object implements the ReportFeedbackSummary interface.
 */
export function instanceOfReportFeedbackSummary(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReportFeedbackSummaryFromJSON(json: any): ReportFeedbackSummary {
    return ReportFeedbackSummaryFromJSONTyped(json, false);
}

export function ReportFeedbackSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportFeedbackSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'feedbackRating': !exists(json, 'feedbackRating') ? undefined : json['feedbackRating'],
        'feedbackComment': !exists(json, 'feedbackComment') ? undefined : json['feedbackComment'],
        'feedbackTimestamp': !exists(json, 'feedbackTimestamp') ? undefined : json['feedbackTimestamp'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function ReportFeedbackSummaryToJSON(value?: ReportFeedbackSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'name': value.name,
        'email': value.email,
        'feedbackRating': value.feedbackRating,
        'feedbackComment': value.feedbackComment,
        'feedbackTimestamp': value.feedbackTimestamp,
        'fileName': value.fileName,
    };
}

