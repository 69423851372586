import { AppDispatch, RootState } from "./store";
import { DeleteInfo, Feature, ResponseError, StorageApi, UploadItemKind, UsersStatusReport, GetReportingUsersApi, GetReportingUploadSummaryApi, GetReportingFeedbackSummaryApi } from "../net";
import axios from "axios";
import { sliceWithThunks } from "./utils";

import { ReportUploadSummary, ReportFeedbackSummary } from "../net";
import { } from "../net";

export interface UploadUrl {
  id: string;
  url: string;
}

const fileMappings: Record<string, File> = {};

export interface UploadingFile {
  id: string;
  name: string;
  url: string;
  progress: number;
  error?: string;
}

export interface AddFeedback {
  srcId?: string;
  feedbackRating?: number;
  feedbackComment?: string;
  fileExtension?: string;
}


interface StorageState {
  isLoading: boolean;
  uploadUrls: UploadUrl[];
  uploadUrlsSucceeded: boolean;
  uploadUrlsFailed?: string;
  uploadingFiles: UploadingFile[];
  uploadingFilesCompleted: boolean;
  deleteAccountStatus: string;
  userUploadReports: ReportUploadSummary[] | null;
  UserResults: UsersStatusReport[] | null;
  UserResultsLoading: boolean;
  UserResultsError?: string;
  feedbackSummariesLoading: boolean;
  feedbackSummariesError?: string;
  uploadSummary: ReportUploadSummary[] | null;
  feedbackSummary: ReportFeedbackSummary[] | null;
  uploadSummaryLoading: boolean;
  feedbackSummaryLoading: boolean;
  uploadSummaryError?: string;
  feedbackSummaryError?: string;
}
const initialState: StorageState = {
  isLoading: false,
  uploadUrls: [],
  uploadUrlsSucceeded: false,
  uploadUrlsFailed: undefined,
  uploadingFiles: [],
  uploadingFilesCompleted: false,
  userUploadReports: null,
  deleteAccountStatus: "",
  UserResults: null,
  UserResultsLoading: false,
  UserResultsError: undefined,
  feedbackSummariesLoading: false,
  feedbackSummariesError: undefined,
  uploadSummary: null,
  feedbackSummary: null,
  uploadSummaryLoading: false,
  feedbackSummaryLoading: false,
  uploadSummaryError: undefined,
  feedbackSummaryError: undefined,
};


export const storageSlice = sliceWithThunks({
  name: "storage",
  initialState,
  reducers: (create) => ({
    getUploadUrls: create.asyncThunk<
      any,
      {
        features: Feature[];
        files: Record<string, File>;
      }
    >(
      async (payload, thunkApi) => {
        const state = (thunkApi.getState() as RootState)
          .storage as StorageState;

        const makeUploadRequestItems = (filesMap: Record<string, File>) => {
          return Object.keys(filesMap).map((key) => {
            const file = filesMap[key];
            let kind!: UploadItemKind;
            switch (file.type) {
              case String((file.type).match(/(video\/.*)/g)):
                kind = UploadItemKind.Video;
                break;
              case String((file.type).match(/(audio\/.*)/g)):
                kind = UploadItemKind.Audio;
                break;
              case "application/pdf":
                kind = UploadItemKind.TextDocument;
                break;
            }
            let encodedKey: string;
            try {
              encodedKey = encodeURIComponent(key);
            } catch (error) {
              console.error('Error encoding key:', error);
              encodedKey = key; 
            }
            fileMappings[encodedKey] = file;
            return { id: encodedKey, kind, extra: {}, fileType: file.type};
          });
        };

        try {
          const uploadUrls = await new StorageApi().uploadUrls({
            uploadInfo: {
              features: payload.features,
              items: makeUploadRequestItems(payload.files),
            },
          });
          return uploadUrls.map((value) => {
            return { id: value.id, url: value.url };
          }) as UploadUrl[];
        } catch (e) {
          return thunkApi.rejectWithValue({
            message:
              (e as ResponseError).response.status === 429
                ? "limit"
                : "Failed to fetch issues.",
          });
        }
      },
      {
        pending: (state) => {
          state.isLoading = true;
        },
        fulfilled: (state, action) => {
          state.uploadUrls = action.payload as UploadUrl[];
          state.uploadUrlsSucceeded = true;
        },
        rejected: (state, action) => {
          action.payload === undefined ? (state.uploadUrlsFailed = "Server may be busy. Please try again after sometime!") :
            state.uploadUrlsFailed = (
              action.payload as { message: string }
            ).message;
        },
        settled: (state) => {
          state.isLoading = false;
          state.uploadUrlsSucceeded = true;
        },
      },
    ),
    fetchUserResults: create.asyncThunk<
      UsersStatusReport[],
      void,
      { rejectValue: { message: string } }
    >(
      async (_, thunkApi) => {
        try {
          const UserResults = await new GetReportingUsersApi().getReportingUsers();
          console.log(UserResults);
          return UserResults;
        } catch (e) {
          return thunkApi.rejectWithValue({
            message: (e as ResponseError).response?.status === 429
              ? "Request limit reached"
              : "Failed to fetch user status,try agin after some time.",
          });
        }
      },
      {
        pending: (state) => {
          state.UserResultsLoading = true;
          state.UserResultsError = undefined;
        },
        fulfilled: (state, action) => {
          state.UserResults = action.payload;
          state.UserResultsLoading = false;
        },
        rejected: (state, action) => {
          state.UserResultsError = (action.payload as { message: string }).message;
          state.UserResultsLoading = false;
        },
      },
    ),
    fetchUploadSummary: create.asyncThunk<
      ReportUploadSummary[],
      { startDate: string; endDate: string },
      { rejectValue: { message: string } }
    >(
      async ({ startDate, endDate }, thunkApi) => {
        try {
          // Pass an object of type GetUploadSummaryRequest
          const uploadSummary = await new GetReportingUploadSummaryApi().getReportingUploadSummary({ startDate, endDate });
          return uploadSummary;
        } catch (e) {
          return thunkApi.rejectWithValue({
            message: (e as ResponseError).response?.status === 429
              ? "Request limit reached"
              : "Failed to fetch upload summary,try agin after some time.",
          });
        }
      },
      {
        pending: (state) => {
          state.uploadSummaryLoading = true;
          state.uploadSummaryError = undefined;
        },
        fulfilled: (state, action) => {
          state.uploadSummary = action.payload;
          state.uploadSummaryLoading = false;
        },
        rejected: (state, action) => {
          state.uploadSummaryError = action.payload?.message;
          state.uploadSummaryLoading = false;
        },
      },
    ),
    fetchFeedbackSummary: create.asyncThunk<
      ReportFeedbackSummary[],
      { startDate: string; endDate: string },
      { rejectValue: { message: string } }
    >(
      async ({ startDate, endDate }, thunkApi) => {
        try {
          const feedbackSummary = await new GetReportingFeedbackSummaryApi().getReportingFeedbackSummary({ startDate, endDate });
          return feedbackSummary;
        } catch (e) {
          return thunkApi.rejectWithValue({
            message: (e as ResponseError).response?.status === 429
              ? "Request limit reached"
              : "Failed to fetch feedback summary.",
          });
        }
      },
      {
        pending: (state) => {
          state.feedbackSummaryLoading = true;
          state.feedbackSummaryError = undefined;
        },
        fulfilled: (state, action) => {
          state.feedbackSummary = action.payload;
          state.feedbackSummaryLoading = false;
        },
        rejected: (state, action) => {
          state.feedbackSummaryError = action.payload?.message;
          state.feedbackSummaryLoading = false;
        },
      }
    ),
    uploadFiles: create.asyncThunk<void, UploadUrl[]>(
      async (payload, thunkApi) => {
        const dispatch = thunkApi.dispatch as AppDispatch;
        dispatch(setUploadingFiles(payload));
        await dispatch(uploadFilesInternal());
      },
      {
        settled: (state) => {
          state.uploadingFilesCompleted = true;
        },
      },
    ),
    setUploadingFiles: create.reducer<UploadUrl[]>((state, action) => {
      state.isLoading = true;
      state.uploadingFiles = action.payload.map((value) => {
        return {
          id: value.id,
          name: fileMappings[value.id].name,
          url: value.url,
          progress: 0,
        };
      });
    }),
    deleteVideos: create.asyncThunk<void, DeleteInfo[]>(
      async (payload, thunkApi) => {
        try {
          await new StorageApi().deleteVideos({
            deleteInfo: payload
          });
        } catch (e) {
          return thunkApi.rejectWithValue({
            message: "Error",
          });
        }
      }
    ),
    deleteAccount: create.asyncThunk<void>(
      async (action, thunkApi) => {
        try {
          await new StorageApi().deleteAccount();
        } catch (e) {
          return thunkApi.rejectWithValue({
            message: "Error deleting account."
          });
        }
      },
      {
        rejected: (state) => {
          state.deleteAccountStatus = "failed";
        },
        fulfilled: (state) => {
          state.deleteAccountStatus = "success";
        },
      },
    ),
    setProgress: create.reducer<{ id: string; progress: number }>(
      (state, action) => {
        const index = state.uploadingFiles.findIndex(
          (value) => value.id === action.payload.id,
        );
        if (index === -1) {
          const file = state.uploadingFiles[index];
          file.progress = 1;
          file.error = `not found: ${action.payload.id}`;
        } else {
          state.uploadingFiles[index].progress = action.payload.progress;
        }
      },
    ),
    uploadFilesInternal: create.asyncThunk(
      async (payload, thunkApi) => {
        const state = (thunkApi.getState() as RootState).storage;
        const dispatch = thunkApi.dispatch as AppDispatch;


        const promises = state.uploadingFiles.map((value) => {
          return axios
            .put(value.url, fileMappings[value.id], {
              onUploadProgress: (progressEvent) => {
                dispatch(
                  setProgress({
                    id: value.id,
                    progress: progressEvent.progress || 0,
                  }),
                );
              },
            })
            .catch((reason) => {
              dispatch(setProgress({ id: value.id, progress: 1 }));
            });
        });
        await Promise.all(promises);
      },
      {
        pending: (state) => {
          state.isLoading = true;
        },
        settled: (state) => {
          state.isLoading = false;
        },
      },
    ),
    addFeedback: create.asyncThunk<{ message: string }, AddFeedback>(
      async (payload, thunkApi) => {
        try {
          await new StorageApi().addFeedback({
            addFeedbackRequest: payload,
          });
          return { message: "Feedback submitted" };
        } catch (e) {
          return { message: "Server may be busy. Please try again after sometime!" };
        }
      },
    ),
    resetAll: create.reducer((state) => {
      return initialState;
    }),
    reset: create.reducer((state) => {
      Object.keys(fileMappings).forEach((key) => delete fileMappings[key]);
      return initialState;
    }),
    resetUserResults: create.reducer((state) => {
      state.UserResults = null;
      state.UserResultsError = undefined;
    }),
    resetFeedbackSummary: create.reducer((state) => {
      state.feedbackSummary = null;
      state.feedbackSummaryError = undefined;
      state.feedbackSummaryLoading = false;
    }),
    resetUploadSummary: create.reducer((state) => {
      state.uploadSummary = null;
      state.uploadSummaryError = undefined;
      state.uploadSummaryLoading = false;
    }),

  }),
  selectors: {
    selectUploadUrls: (sliceState) => sliceState.uploadUrls,
    selectUploadUrlsFailed: (sliceState) => sliceState.uploadUrlsFailed,
    selectUploadUrlsSucceeded: (sliceState) => sliceState.uploadUrlsSucceeded,
    selectUploadingFiles: (sliceState) => sliceState.uploadingFiles,
    selectUploadingFilesCompleted: (sliceState) =>
      sliceState.uploadingFilesCompleted,
    selectDeleteAccountStatus: (sliceState) => sliceState.deleteAccountStatus,
    selectUserResults: (sliceState) => sliceState.UserResults,
    selectUserUploadReports: (sliceState) => sliceState.userUploadReports,
    selectUserResultsLoading: (sliceState) => sliceState.UserResultsLoading,
    selectUserResultsError: (sliceState) => sliceState.UserResultsError,
    selectLoading: (sliceState) =>
      sliceState.isLoading || sliceState.uploadSummaryLoading || sliceState.feedbackSummaryLoading,
    selectUploadSummary: (sliceState) =>
      sliceState.uploadSummary,
    selectUploadSummaryLoading: (sliceState) =>
      sliceState.uploadSummaryLoading,
    selectUploadSummaryError: (sliceState) =>
      sliceState.uploadSummaryError,
    selectFeedbackSummary: (sliceState) =>
      sliceState.feedbackSummary,
    selectFeedbackSummaryLoading: (sliceState) =>
      sliceState.feedbackSummaryLoading,
    selectFeedbackSummaryError: (sliceState) =>
      sliceState.feedbackSummaryError,
  },
});

export const {
  selectLoading,
  selectUploadUrls,
  selectUploadUrlsFailed,
  selectUploadUrlsSucceeded,
  selectUploadingFiles,
  selectUploadingFilesCompleted,
  selectDeleteAccountStatus,
  selectUserResults,
  selectUserResultsLoading,
  selectUserResultsError,
  selectUploadSummary,
  selectUploadSummaryLoading,
  selectUploadSummaryError,
  selectFeedbackSummary,
  selectFeedbackSummaryError,
  selectFeedbackSummaryLoading
} = storageSlice.getSelectors((rootState: RootState) => rootState.storage);

export const {
  fetchUserResults,
  deleteVideos,
  deleteAccount,
  getUploadUrls,
  setUploadingFiles,
  setProgress,
  uploadFilesInternal,
  uploadFiles,
  addFeedback,
  reset,
  resetUserResults,
  resetUploadSummary,
  fetchUploadSummary,
  fetchFeedbackSummary

} = storageSlice.actions;
