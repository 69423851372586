import { useSelector } from "react-redux";
import Protected from "../protected";
import Dashboard from "./dashboard";
import { ErrorRoutePage } from "../../error-route-page";
import { lazy, Suspense } from "react";
import Loading from "../../loading";
import { RootState } from "../../redux/store";
import { Navigate } from "react-router-dom";
const SincerityList = lazy(() => import("./main/sincerity-list"));
const DashboardUploads = lazy(() => import("./uploads/uploads"));
const DashboardChat = lazy(() => import("./chat/chat"));
const Settings = lazy(() => import("./settings/settings"));
const Reporting = lazy(() => import("./Reporting"));
const ReportingWrapper = () => {
  const userEmail = useSelector((state: RootState) => state.auth.user?.email);
  const isVoiceraUser = userEmail?.endsWith("@voicera.io");

  if (!isVoiceraUser) {
    return <Navigate to="/dashboard" replace />;
  }
  return <Reporting />;
};

export const dashboardRoutes = {
  path: "/dashboard",
  element: (
    <Protected>
      <Suspense fallback={<Loading />}>
        <Dashboard />
      </Suspense>
    </Protected>
  ),
  errorElement: <ErrorRoutePage resetErrorBoundary={() => { }} />,
  children: [
    {
      index: true,
      element: <SincerityList />,
    },
    {
      path: "upload",
      element: <DashboardUploads />,
    },
    {
      path: "chat",
      element: <DashboardChat />,
    },
    {
      path: "settings",
      element: <Settings />,
    },
    {
      path: "reporting",
      element: (
        <ReportingWrapper />
      ),
    },
  ],
};
