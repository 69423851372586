/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportUploadSummary
 */
export interface ReportUploadSummary {
    /**
     * 
     * @type {string}
     * @memberof ReportUploadSummary
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportUploadSummary
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportUploadSummary
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportUploadSummary
     */
    numberofuploads?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportUploadSummary
     */
    numberoffeedbacks?: number;
}

/**
 * Check if a given object implements the ReportUploadSummary interface.
 */
export function instanceOfReportUploadSummary(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReportUploadSummaryFromJSON(json: any): ReportUploadSummary {
    return ReportUploadSummaryFromJSONTyped(json, false);
}

export function ReportUploadSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportUploadSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'numberofuploads': !exists(json, 'numberofuploads') ? undefined : json['numberofuploads'],
        'numberoffeedbacks': !exists(json, 'numberoffeedbacks') ? undefined : json['numberoffeedbacks'],
    };
}

export function ReportUploadSummaryToJSON(value?: ReportUploadSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'name': value.name,
        'email': value.email,
        'numberofuploads': value.numberofuploads,
        'numberoffeedbacks': value.numberoffeedbacks,
    };
}

