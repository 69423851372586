import Sidebar from "./sidebar/sidebar";
import { Outlet } from "react-router-dom";
import { useState } from "react";

export default function Dashboard() {
	const [showReloadMsg, setShowReloadMsg] = useState(false);

	window.addEventListener('error', (error: any) => {
		if (/Loading chunk [\d]+ failed/.test(error.message)) {
			setShowReloadMsg(true);
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	})

	return (
		<div className="flex flex-grow h-full w-full">
			<div className="flex flex-row w-full">
				<Sidebar />

				{
					showReloadMsg ? (
						<div className="bg-white p-10 rounded absolute w-fit h-fit content-center ml-[35%] mt-[5%]">
							<h2>A new version is released. Need to reload the page to apply changes.</h2>
						</div>
					) : <></>
				}

				<div
					id="content"
					className="flex grow w-full border-none overflow-y-scroll overflow-x-auto p-4"
				>
					<Outlet></Outlet>
				</div>
			</div>
		</div>
	);
}
